import './App.css';
import React, {useEffect, useState} from 'react';
import {Center, ChakraProvider, Grid, Image} from '@chakra-ui/react';
import {RtlProvider} from "./components/RTLProvider/RTLProvider";
import theme from './theme/theme.js';
import Persons from "./Persons";
import logo from './logo.png';
import "@fontsource/alef/400.css";
import "@fontsource/alef/700.css";
// import data from './dummy.js'

function App() {
    const [registrations, setRegistrations] = useState([]);
    const [totalRegistered, setTotalRegistered] = useState(0);

    useEffect(() => {
        // setTotalRegistered(data.total);
        // setRegistrations(data.registrants.map(r => ({
        //     name: r.name,
        //     date: new Date(r.date)
        // })));
        fetch('/api/registrations')
            .then(response => response.json())
            .then(data => {
                console.log(data);
                setTotalRegistered(data.total);
                setRegistrations(data.registrants.map(r => ({
                    name: r.name,
                    date: new Date(r.date)
                })));
            });
    }, []);

    return (
        <ChakraProvider theme={theme} resetCss={false}>
            <RtlProvider>
                <Center w='100%'>
                    <Grid w='100%'>
                        <Image
                            boxSize='125px'
                            src={logo}
                            margin="auto"
                            paddingBottom="0"
                        />
                        <Persons
                            total={totalRegistered}
                            registrations={registrations}
                        />
                    </Grid>
                </Center>
            </RtlProvider>
        </ChakraProvider>
    );
}

export default App;

