import {Avatar, Box, Flex, Text, useColorModeValue} from "@chakra-ui/react";
import React from "react";
import {AiOutlineUser} from "react-icons/ai";

function PersonRow(props) {
    const textColor = useColorModeValue("gray.700", "white");
    const {name, date} = props;
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const colors = ['teal.400', 'pink.400', 'orange.400', 'cyan.400'];

    return (
        <Flex my="1rem" justifyContent="space-between" boxShadow='dark-lg' minH='120px'>
            <Flex alignItems="center">
                <Box
                    me="12px"
                    borderRadius="30%"

                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    w="24px"
                    h="24px"
                    p="36px"
                >
                    <Avatar bg={
                        date.getTime() > yesterday.getTime()
                            ? "green.400"
                            : colors[Math.floor(Math.random() * colors.length)]
                    } icon={<AiOutlineUser fontSize='1.5rem' />} />
                </Box>
                <Flex direction="column">
                    <Text
                        fontSize="2xl"
                        color={textColor}
                        fontWeight="bold"
                    >
                        {name}
                    </Text>
                    {/*<Text*/}
                    {/*    fontSize="xl"*/}
                    {/*    color="gray.400"*/}
                    {/*    fontWeight="semibold"*/}
                    {/*>*/}
                    {/*    {date.toLocaleDateString()}*/}
                    {/*</Text>*/}
                </Flex>
            </Flex>
        </Flex>
    );
}

export default PersonRow;
