// Chakra imports
import {Center, Flex, SimpleGrid, Text, useColorModeValue} from "@chakra-ui/react";
// Custom components
import PersonRow from "./components/Tables/PersonRow";
import React from "react";

const Persons = ({
                     total,
                     registrations,
                 }) => {
    // Chakra color mode
    const textColor = useColorModeValue("gray.700", "white");

    return (
        <>
            <Center>
                <Flex margin="auto" my="12px">
                    <Text
                        color={textColor}
                        fontSize="4xl"
                        fontWeight='bold'>
                        {total} שגרירים עד כה!
                    </Text>
                </Flex>
            </Center>
            <Center w='100%'>
                <SimpleGrid minChildWidth='300px' spacing='40px' w='90%'>
                    {registrations.map((row) => {
                        return (
                            <PersonRow
                                key={row.name}
                                name={row.name}
                                date={row.date}
                            />
                        )
                            ;
                    })}
                </SimpleGrid>
            </Center>
        </>
    )
        ;
};

export default Persons;
